@import "../../../styles/variables.module";

$extra-padding: 0;
$stations-width: 18rem;
$stations-width-mid: 14rem;
$stations-width-mobile: 12rem;
// $stations-width-mobile: 9.6rem;
$station-horizontal-padding: 1rem;

body:global(.arrow-navigation) {
  .radio_station {
    &:focus {
      @include media(">tablet") {
        // transform: scale(1.2);
        filter: drop-shadow(0px 0px 24px #000000);
      }
    }
  }
}

body:global(.low-performance) {
  .header,
  .radio_station {
    transition: none !important;
  }
  .placeholder_item {
    &:before {
      display: none;
    }
  }
}

.radio_list {
  overflow-x: auto;
  // padding: calc(3.2rem + #{$extra-padding}) 1.6rem;
  // margin: -1 * $extra-padding -1.6rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  // html[dir="rtl"] & {
  //   @include media(">desktop720p") {
  //     padding: calc(3.2rem + #{$extra-padding}) 5rem;
  //     margin: -1 * $extra-padding -5rem;
  //     margin-left: -3.4rem;
  //   }
  // }
  @include media("<=tablet") {
    // padding: calc(3.2rem + #{$extra-padding}) 0rem $extra-padding;
  }

  @include media(">desktop720p") {
    // padding: calc(3.2rem + #{$extra-padding}) 5rem;
    // margin: -1 * $extra-padding -5rem;
    // margin-right: -3.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.radio_station_wrapper {
  width: 100%;
}
.radio_station {
  border-radius: 20px;
  padding: 2.4rem;
  transition: 0.5s all;
  object-fit: cover;
  cursor: pointer;
  z-index: $z-index-base;
  width: 100%;
  display: flex;
  
  @include media("<=desktop720p") {
    padding: 1.6rem;
  }

  @include media("<=tablet") {
    padding: 0 0 1.6rem;
  }

  @include media(">tablet") {
  &:hover {
      background-color: rgba($color-light, 0.15);
    }
  }

  &:focus {
    outline: none;
  }

  .cover_wrapper {
    position: relative;
    width: $stations-width;
    height: $stations-width;
    border-radius: 15px;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.25);

    @include media("<=desktop720p") {
      width: $stations-width-mid;
      height: $stations-width-mid;
    }
    @include media("<=tablet") {
      width: $stations-width-mobile;
      height: $stations-width-mobile;
    }
  }

  .cover {
    border-radius: 15px;

    &.no_image {
      background: $color-light-transparent-15;
      position: relative;

      img {
        width: $stations-width;
        height: $stations-width;
        margin: 1rem;

        @include media("<=desktop720p") {
          width: $stations-width-mid;
          height: $stations-width-mid;
        }
        @include media("<=tablet") {
          width: $stations-width-mobile;
          height: $stations-width-mobile;
        }
      }
    }
  }

  .station_title_wrapper {
    flex: 1 1;
    padding-left: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @include media("<=tablet") {
      padding-left: 1.6rem;
      
    }
  }
  .station_title {
    color: $color-light;
    font-size: 3.4rem;
    line-height: 4.8rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-shadow: 0px 4.75px 4.75px rgba(0, 0, 0, 0.25);
    letter-spacing: -0.68px;
    margin-bottom: 0.4rem;

    @include media("<=tablet") {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
  .station_subtitle {
    color: $color-light;
    opacity: 0.6;
    text-shadow: 0px 4.75px 4.75px rgba(0, 0, 0, 0.25);
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.4rem;
    letter-spacing: -0.3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;

    @include media("<=tablet") {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  &.show_station_names.show_station_names {
    margin-bottom: 3.2rem;
  }
}

.row {
  display: flex;

  &::after {
    content: "";
    flex: 0 0 calc(100vw - 17.2rem - 4.2rem);

    @include media("<=tablet") {
      flex: 0 0 calc(100vw - 17.2rem - 0.8rem);
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      content: "";
      flex: 0;
    }
    .radio_station {
      margin-bottom: 0;
    }
  }
  &.wrapped {
    overflow-x: hidden;
    flex-wrap: wrap;
    padding-bottom: 3rem;

    .radio_station {
      margin: 1.6rem;
    }

    .placeholder_item {
      @include media("<=tablet") {
        width: 11.2rem;
        height: 11.2rem;
      }
    }
    .radio_station {
      @include media("<=tablet") {
        margin: 0rem;
      }
    }
  }
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  width: $stations-width;
  height: $stations-width;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  @include media("<tablet") {
    width: $stations-width-mobile;
    height: $stations-width-mobile;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: $stations-width;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;

    @include media("<tablet") {
      width: $stations-width-mobile;
    }
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

:export {
  radioStationOuterWidth: $stations-width + $station-horizontal-padding + 1.6rem;
  radioStationOuterWidthMobile: $stations-width-mobile +
    $station-horizontal-padding + 1.6rem;
}
