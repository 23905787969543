@import "~include-media/dist/_include-media.scss";
$breakpoints: (
  phone: 20em,
  //320px
  phoneL: 23.75em,
  //380px
  phoneXL: 26.25em,
  //420px
  tablet: 51.25em,
  //820px
  desktop720p: 80em,
  //1280px
  desktop768p: 85.375em,
  //1336px
  desktop1600: 100em,
  //1600px
  desktop1920: 120em,
  //1920px
  desktop2560: 160em,
  //1600px
  desktop3840: 240em,
  //1600px
);
$body-background-color: #010103;
$content-max-width: 192rem;
$color-light: #ffffff;
$color-gray: #cacaca;
$color-light-transparent-15: rgba(255, 255, 255, 0.15);
$color-accent: #ffca41;
$color-success: rgba(83, 199, 101, 1);
$color-progress: #4a88ff;
$color-whitelabel-main: #FFBE19;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$color-primary: #3756d3;
$color-black: #00070e;
$color-text-muted: rgba(255, 255, 255, 0.6);
$color-text-gray: #7f868e;
$color-text-dark: #222222;
$color-danger: #e34141;
$background-gradient: #000;

$z-index-base: 1;
$player-minimized-z-index: $z-index-base + 2;
$player-fullscreen-z-index: $z-index-base + 3;
$menu-space: 12.3rem;

$border-radius: 16px;
$border-radius-small: 8px;
$border-radius-tabs: 6.89px;


$container-padding: 4rem 1.6rem 0;
$base-padding: 1.6rem;
$content-min-height: calc(100vh - 18rem);
$header-height: 9.9rem;

:export {
  progressColorPrimaryMuted: transparent;
  progressColorFill: $color-accent;
  progressColorPrimary: $color-accent;
  contentMaxWidth: $content-max-width;
}
