@import "../../../styles/variables.module";
// @import "node_modules/react-slick/slick/slick.css";
// @import "~react-slick/slick/slick-theme.css";
// @import "node_modules/slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

.popup_wrapper {
  position: fixed;
  top: 0;
  top: constant(safe-area-inset-top, 0);
  top: env(safe-area-inset-top, 0);
  bottom: 0;
  bottom: constant(safe-area-inset-bottom, 0);
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;
  background-color: $color-black;
  z-index: $player-fullscreen-z-index + 1;
  overflow: auto;

  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/Catalog.All.Radio.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;

  &.under_header {
    top: $header-height;
    top: calc(constant(safe-area-inset-top, 0) + $header-height);
    top: calc(env(safe-area-inset-top, 0) + $header-height);
  }
}

.catalog_header {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100vw;
  z-index: $z-index-base + 3;
  margin-right: 6rem;

  @include media("<desktop768p") {
    margin-right: 2rem;
  }
  @include media("<desktop720p") {
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;
  }

  .catalog_close {
    width: 8rem;
    height: 8rem;
    flex-basis: 14rem;
    display: block;
    justify-content: center;
    align-items: center;
    top: 6rem;
    left: 6rem;
    position: sticky;
    border-radius: 50%;
    transition: all 0.5s;

    &:hover {
      @include media(">desktop720p") {
        background-color: rgba($color-light, 0.15);
      }
    }

    @include media("<desktop720p") {
      width: 6rem;
      height: 6rem;
      left: 2.4rem;
      top: 2.4rem;
    }

    svg {
      width: 8rem;
      height: 8rem;
      transform: rotate(90deg);

      @include media("<desktop720p") {
        width: 4.2rem;
        height: 4.2rem;
      }
    }
  }
}

.content {
  padding: 3rem 3rem 0 6rem;

  @include media("<desktop720p") {
    padding: 6.2rem 1.6rem 0;
  }
}
.content_main {
  display: flex;
}

.genres_wrapper {
  flex: 39.7rem 0 0;
  width: 39.7rem;
  position: relative;
  padding-top: 1rem;

  @include media("<=desktop720p") {
    display: none;
  }

  .genres_scroll_wrapper {
    position: sticky;
    top: 4rem;
    height: calc(100vh - 8rem);
  }
  .genres_inner {
    width: 25.7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    height: calc(100vh - 8rem);
  }

  .genre {
    color: rgba($color-light, 0.6);
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 3.4rem;
    letter-spacing: -0.52px;
    padding: 0.8rem 2.4rem;
    cursor: pointer;
    margin: 0.8rem 0;
    border-radius: 25px;
    transition: all 0.5s;

    @include media("<=desktop768p") {
      font-size: 2.2rem;
    }

    &.active {
      color: $color-text-dark;
      background-color: $color-whitelabel-main;
    }
    &:hover {
      color: $color-light;
      background: rgba($color-light, 0.1);
    }
  }
}
.buttons {
  position: absolute;

  right: 0;
  top: 1rem;
  bottom: 1rem;

  .arrow {
    transform: rotate(90deg);
    position: absolute;
    right: 0;
    bottom: 0;

    svg {
      width: 3.2rem;
      height: 3.2rem;
    }

    &.arrow_up {
      transform: rotate(270deg);
      top: 0;
      bottom: unset;
    }
    &:hover {
      svg {
        border-radius: 50%;
        background-color: rgba($color-light, 0.15);
      }
    }
  }
}

.stations_wrapper {
  margin: 0 0 0 5rem;
  flex: 100% 1 0;

  @include media("<desktop720p") {
    margin: 2rem 0 0;
  }
}

.pills_wrapper {
  display: none;

  @include media("<=desktop720p") {
    display: block;
  }
}
.pills {
  display: flex;
  flex-shrink: 1;
  width: auto;
  margin: 1rem auto;
  height: 4.8rem;
  align-items: center;
  flex-shrink: 0;
  background: rgba($color-light, 0.1);
  align-items: center;
  justify-content: center;
}
.pill {
  color: rgba($color-light, 0.6);
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 2.8rem;
  letter-spacing: -0.44px;
  padding: 1rem 3rem;
  border-radius: 48px;
  cursor: pointer;
  transition: all 0.3s;

  @include media(">desktop720p") {
    &:not(.pill_active):hover {
      color: $color-light;
    }
  }
}
.pill_active {
  color: $color-text-dark;
  background-color: $color-whitelabel-main;
}

@include media("<=desktop720p") {
  .pills {
    width: 100vw;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    justify-content: flex-start;
    background: transparent;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    padding: 0 1.6rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .pill {
    white-space: nowrap;
    text-align: center;
    //   opacity: 0.5;
    //   font-size: 1.2rem;
    // }
    // .pill_neighbour {
    opacity: 0.7;
    font-size: 1.4rem;
  }
  .pill_active {
    opacity: 1;
    font-size: 1.6rem;
  }
}
