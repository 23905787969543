@import "../styles/variables.module";

.container {
  padding: 1.6rem 1.6rem;
  margin: 0 auto;

  @include media(">desktop720p") {
    min-height: calc(100vh - 20rem);
    padding: 1.6rem 3.4rem 0;
  }
  @include media("<=tablet") {
    padding: 0 1.6rem;
  }
}

.inner-container {
  max-width: $content-max-width;
  margin: 0 auto;
}

.home_stations_wrapper {
  margin-bottom: 0;
}
.home_stations_wrapper + .home_stations_wrapper {
  @include media(">tablet") {
    margin-top: -4.2rem;
  }
  @include media("<=tablet") {
    margin-top: -2.2rem;
  }
}
.home_categories_wrapper {
  @include media(">tablet") {
    margin-top: -1rem;
  }
}
.home_podcasts_wrapper {
  margin-bottom: 3rem;
}

.pills_wrapper {
  display: flex;
}
.pills {
  display: flex;
  flex-shrink: 1;
  width: auto;
  margin: 1rem auto;
  height: 4.8rem;
  align-items: center;
  flex-shrink: 0;
  border-radius: 48px;
  background: rgba($color-light, 0.1);
  align-items: center;
  justify-content: center;
}
.pill {
  color: rgba($color-light, 0.6);
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 2.8rem;
  letter-spacing: -0.44px;
  padding: 1rem 3rem;
  border-radius: 48px;
  cursor: pointer;
  transition: all 0.3s;

  @include media(">desktop720p") {
    &:not(.pill_active):hover {
      color: $color-light;
    }
  }
}
.pill_active {
  color: $color-text-dark;
  background-color: $color-whitelabel-main;
}

@include media("<=desktop768p") {
  .pills {
    height: 4rem;
  }
  .pill {
    font-size: 1.8rem;
    line-height: 2rem;
    letter-spacing: -0.36px;
    padding: 1rem 1.8rem;
  }
}
@include media("<=desktop1600") {
  .pills {
    width: 100vw;
    margin: 0 -1.6rem;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    justify-content: flex-start;
    background: transparent;
    border-radius: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .pill {
    white-space: nowrap;
    opacity: 0.7;
    font-size: 1.4rem;
  }
  .pill_active {
    opacity: 1;
    font-size: 1.6rem;
  }

  .container :global(.slick-slider) {
    width: 100vw;
    margin: 0 -1.6rem;
  }
}
